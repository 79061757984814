import type { PropsWithChildren, ReactElement, ReactNode, SyntheticEvent } from "react";
import { Children, cloneElement, useId, useState } from "react";
import { twMerge } from "tailwind-merge";
import type { PopupProps } from "./Popup";
import Popup from "./Popup";

export type TooltipProps = PropsWithChildren<{
  title: string | ReactNode;
  placement?: PopupProps["placement"];
  className?: string;
  color?: "default" | "error";
  parentState?: TooltipState;
  /**
   * Allows the parent to conditionally render the tooltip
   * @default false
   */
  noop?: boolean;
}>;

export default function Tooltip({
  title,
  children,
  placement = "top",
  className,
  color = "default",
  parentState,
  noop = false,
}: TooltipProps) {
  const child = Children.only(children);
  const internalState = useState<HTMLElement>();
  const [anchorEl, setAnchorEl] = parentState
    ? [parentState.anchorEl, parentState.setAnchorEl]
    : internalState;

  const id = useId();

  if (noop) {
    return child;
  }

  const clonedChild = cloneElement(child as ReactElement, {
    onMouseEnter: (e: SyntheticEvent<HTMLElement>) => {
      setAnchorEl(e.currentTarget);
    },
    onMouseLeave: () => {
      setAnchorEl(undefined);
    },
    // The element that describes this doesn't exist if the tooltip is not open
    ...(anchorEl ? { "aria-describedby": id } : undefined),
  });

  return (
    <>
      {clonedChild}

      <Popup open={!!anchorEl} anchor={anchorEl} placement={placement} id={id} className="z-50">
        <div
          className={twMerge(
            "mx-1 my-0.25 flex w-fit max-w-[300px] animate-fadeIn rounded-lg px-1 py-0.5 text-[13px] font-medium text-white shadow-lg",
            color === "error" ? "bg-tm-error" : "bg-[#555]",
            className
          )}
        >
          {title}
        </div>
      </Popup>
    </>
  );
}

export type TooltipState = ReturnType<typeof useTooltipState>;

export const useTooltipState = (defaultAnchorEl?: HTMLElement | null) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(defaultAnchorEl);

  return {
    setAnchorEl,
    anchorEl,
    isOpen: !!anchorEl,
    isClosed: !anchorEl,
  };
};
