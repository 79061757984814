import { type PopupProps } from "@tamarack/ui/Popup";
import Tooltip from "@tamarack/ui/Tooltip";
import HelpIcon from "@tamarack/ui/icons/HelpIcon";
import type { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import type { Size } from "./Button";

export default function HelpInfo({
  children,
  placement,
  className,
  size,
}: PropsWithChildren & {
  placement?: PopupProps["placement"];
  size?: Size;
  className?: string;
}) {
  return (
    <Tooltip title={children} placement={placement}>
      <HelpIcon
        size={size === "touch" ? 28 : 16}
        className={twMerge(
          "cursor-help rounded-lg border-tm-divider text-tm-secondary-lighter hover:bg-tm-divider-lightest hover:text-tm-secondary hover:opacity-100",
          className
        )}
      />
    </Tooltip>
  );
}
